import { AxiosError } from 'axios';
import React, { FC } from 'react';
import { CheckCircle, XOctagon } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { Button } from '../components/Button';
import { PasswordInput } from '../components/PasswordInput';
import { PasswordRules } from '../components/PasswordRules';
import { H4 } from '../components/Typography';
import { resetPassword as resetPasswordAction } from '../lib/actions/me';
import { getBodyMessage } from '../utils/function-utils';
import { Container } from './commonStyles';

export const ResetPasswordForm: FC<{ token: string | null }> = ({ token }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const password = watch('password', '');
  const onSubmit = async (values: { password: string }) => {
    if (!token) {
      toast.error('Invalid token', {
        icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
      });
      return;
    }

    try {
      await dispatch(resetPasswordAction(token, values.password));
      toast.success('Password was reset successfully, please login again', {
        icon: <CheckCircle className="w-8" size={32} color="#3CB043" />,
      });
      reset();
    } catch (err) {
      toast.error(getBodyMessage(err as AxiosError), {
        icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">Forgot Password</H4>

        <PasswordInput
          label="Password"
          className="mb-4"
          error={!!errors.password}
          inputStyle={{ color: '#333' }}
          {...register('password', {
            required: true,
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&\[\]\{\}_\-\(\)])[A-Za-z\d@#$!%*?&\[\]\{\}_\-\(\)]{6,}$/gm,
          })}
        />

        <PasswordRules password={password} />

        <div className="flex justify-end mt-4">
          <Button type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </Container>
    </form>
  );
};
